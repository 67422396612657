import React, { PureComponent } from "react"

/* Alicorn quit game */
import { postMessage } from '@alicorn/iframe-message-sender'

/* Constants */
import { env, utils } from "../constants"

/* PureComponents */
import Picture from "./Picture"



/* PureComponent Game Info */
class GameInfo extends PureComponent {

    constructor() {
        super()

        this.state = {
            pinned: false
        }
    }

    render = () => {

        const { pinned } = this.state
        const { game, currency, payout, close } = this.props
        const { max, min, maxPay } = game

        return (
            <div className={`game-info ${pinned ? 'pinned' : 'default'}`}>

                <div className="game-info-inner">

                    <div onClick={() => close()} className="game-info-close">
                        <Picture src={`${env.mediapoint}/images/close.png`} alt="Close" />
                    </div>

                    <div className="game-info-text">QUEEN OF SPADES</div>
                    <div className="game-info-rate">{utils.getWithCurrency(min, currency)} - {utils.getWithCurrency(max, currency)}</div>

                    <div onClick={() => this.setState({ pinned: !pinned })} className="game-info-pin">
                        <Picture src={`${env.mediapoint}/images/pin.png`} alt="Pin" />
                    </div>

                </div>

                <div className="game-info-max-payout">{utils.translate("Maximum payout")} <span>{utils.getWithCurrency(maxPay, currency)}</span></div>

                {payout}

            </div>
        )
    }

}

export default GameInfo